.introduction {
    width: 100%;
    display:flex;
    flex-direction: row;

    .text {
        height: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;

        p {
            max-width: 400px;
        }
    } 


    #bulli{
        max-height:100%;
        height: 100vh;
        width: auto;
        justify-self: flex-end;
    }
};

@media only screen and (max-width: 600px) {
    #bulli {
        display: none;
    }
}