.App {
  text-align: center;
  min-height: 100vh;
  background-color: #d1e4dd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  scroll-behavior: smooth;

  &-logo{
    height: 40vmin;
    pointer-events: none;
  }
}


