.page-section {
    height: 100vh;
    max-height:100vh;
}
.mint{
    background-color: #d1e4dd;
}

.white{
    // not necessary .. but in case we will go with a darker white :)
    background-color: #fff;
}