#house {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s linear forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}